"use client";

import { ShopCartTransaction } from "@/lib/cart/cart-types";
import { ManualPromotion } from "@/lib/shop/shop-types";
import { memo, useMemo } from "react";
import CartTransactionRow from "./cart-transaction-display";

interface TransactionsListProps {
    transactions: ShopCartTransaction[];
    productID: number;
    productName: string;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly: boolean;
    manualPromotionsList: ManualPromotion[] | null;
    thumbnailImageURL?: string;
    minOrderUnits: number;
    baseUnitPrice: number;
}

const MemoizedCartTransactionDisplay = memo(CartTransactionRow);

const createEmptyTransaction = (
    productID: number,
    productName: string,
    wholesaleUnitsPerCase: number,
    sellByCaseOnly: boolean,
    minOrderUnits: number,
    baseUnitPrice: number
): ShopCartTransaction => ({
    cartTransactionID: null,
    manualPromotionID: null,
    productID,
    productName,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    numUnits: 0,
    unitPrice: baseUnitPrice,
    unitFullPrice: 0,
    extPrice: 0,
    discount: 0,
    quantityPromotionID: null,
    productThumbnailImageURL: "",
    productState: "",
    minOrderUnits
});

export function TransactionsList({
    transactions,
    productID,
    productName,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    manualPromotionsList,
    minOrderUnits,
    baseUnitPrice
}: TransactionsListProps) {
    const { sortedTransactions, hasBaseTransaction, inUsePromotions, maxTransactionID } =
        useMemo(() => {
            const inUsePromotions: number[] = [];
            let hasBaseTransaction = false;
            let maxTransactionID = 0;

            const sortedTransactions = [...transactions].sort((a, b) => {
                if (a.manualPromotionID === null && b.manualPromotionID !== null) return -1;
                if (a.manualPromotionID !== null && b.manualPromotionID === null) return 1;
                return (a.cartTransactionID ?? 0) - (b.cartTransactionID ?? 0);
            });

            for (const transaction of sortedTransactions) {
                if (transaction.manualPromotionID) {
                    inUsePromotions.push(transaction.manualPromotionID);
                } else {
                    hasBaseTransaction = true;
                }

                if (transaction.cartTransactionID) {
                    maxTransactionID = Math.max(maxTransactionID, transaction.cartTransactionID);
                }
            }

            return {
                sortedTransactions,
                hasBaseTransaction,
                inUsePromotions,
                maxTransactionID
            };
        }, [transactions]);

    const showAddNewTransaction = manualPromotionsList || !hasBaseTransaction;

    const emptyTransaction = useMemo(
        () =>
            showAddNewTransaction
                ? createEmptyTransaction(
                      productID,
                      productName,
                      wholesaleUnitsPerCase,
                      sellByCaseOnly,
                      minOrderUnits,
                      baseUnitPrice
                  )
                : null,
        [
            showAddNewTransaction,
            productID,
            productName,
            wholesaleUnitsPerCase,
            sellByCaseOnly,
            minOrderUnits,
            baseUnitPrice
        ]
    );

    return (
        <div className="w-full space-y-4 md:space-y-1">
            {sortedTransactions.map((transaction) => (
                <MemoizedCartTransactionDisplay
                    transaction={transaction}
                    manualPromotionsList={manualPromotionsList}
                    key={`${transaction.cartTransactionID}-${transaction.numUnits}`}
                    inUsePromotions={inUsePromotions}
                    hasBaseTransaction={true}
                    backorderUnits={transaction.backorderNumUnits}
                />
            ))}
            {emptyTransaction && (
                <MemoizedCartTransactionDisplay
                    key={`new-transaction-${maxTransactionID}`}
                    manualPromotionsList={manualPromotionsList}
                    transaction={emptyTransaction}
                    inUsePromotions={inUsePromotions}
                    backorderUnits={0}
                    hasBaseTransaction={hasBaseTransaction}
                />
            )}
        </div>
    );
}
